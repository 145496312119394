import React from 'react';
import { Col, Row } from 'reactstrap';
import logoMisiones from '../../assets/img/brand/logo-misiones-v2.webp';
import industryCompetitive from '../../assets/img/brand/industry-competitive.png';
const ContactsFooter = () => {
  return (
    <Row className="align-items-center w-100">
      <Col md="4" xs="12" className="container-logo-landing-footer-left">
        <div className="logo-landing-footer-left">
          <img src={industryCompetitive} alt="ministerio digital" className="img-fluid"></img>
        </div>
      </Col>
      <Col md="4" xs="12" className="text-center h-100 w-100 my-3 d-flex justify-content-center  ">
        <div className="logo-landing-footer">
          <img src={logoMisiones} alt="ministerio digital" className="img-fluid "></img>
        </div>
      </Col>

      <Col md="4" xs="12" className="text-white right-footer-col text-center">
        <p className="mb-0">
          <a href="mailto:dgi_registro@industria.misiones.gob.ar" className="text-white mr-2">
            dgi_registro@industria.misiones.gob.ar
          </a>
          <i className="fa fa-envelope text-white"></i>
        </p>
        <p className="mb-0">
          <a href="mailto:registroycontrolindustrial@gmail.com" className="text-white mr-2">
            registroycontrolindustrial@gmail.com
          </a>
          <i className="fa fa-envelope text-white "></i>
        </p>
        <p className="mb-0">
          <a href="tel:+54937644447285" className="text-white mr-2">
            0376 - 44447285
          </a>
          <i className="fa fa-phone text-white"></i>
        </p>
      </Col>
    </Row>
  );
};

export default ContactsFooter;
